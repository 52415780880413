.educationContainer{
    width: 100%
}
.educationTitle{
    font-family: "Barbie-Antiguo";
    color: #E10686; 
    font-size: 400%;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
}


/* ToDo : arreglar los estilos de las cards, se desbordan en mobile */
.bootcampCardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90vw;
    margin: 0 auto;
    margin-bottom: 10vh;
    padding: 5px
  }
.aboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 95vw;
    margin: 0 auto;
}

.aboutTitle{
    font-family: "Barbie-Antiguo";
    color: #E10686; 
    font-size: 400%;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
}

.infoContainer{
    background: #ffd0e7;
    border: 2px solid #f300a3 ;
    border-radius: 5px;
    color:#f300a3; 
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10vh;
    padding: 2vh;
}

.textContainer{
    font-family: Avant Garde Gothic,sans-serif;
    font-weight: 500;
    line-height: 1.2;
}



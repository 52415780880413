
.navBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    align-content: center;
    align-items: center;
    background: #E10686;
    width: 100vw;
    height: 7vh;
    border-radius: 2px;
    margin: 0 auto;

}

  .navBar img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }


  
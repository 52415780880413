.flipCard {
    background-color: transparent;
    width: 350px;
    height: 500px;
    font-family: sans-serif;
    padding: 6px;

  }
  
  .title {
    font-size: 4vw;
    font-weight: 900;
    text-align: center;
    margin: 0;
    font-family: "Barbie-Dolly";
    color: #E10686;
    transform: rotate(-9deg);
    -webkit-text-stroke: 0.5px white; 
    text-stroke: 0.5px white;
    text-shadow: 1px 1px 3px #404040;
  }
  
  .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flipCard:hover .flipCardInner {
    transform: rotateY(180deg);
  }
  
  .flipCardFront, .flipCardBack {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid #e10686;
    border-radius: 1rem;
  }
  
  .flipCardFront {
    background: linear-gradient(120deg,#e10686 60%, #e10686  88%,
    #55ddff 40%,#55ddff 48%);
    color: white;
    font-family: Avant Garde Gothic,sans-serif;
  }
  
  .flipCardBack {
    background:  #e10686 ;
    color: #ffd0e7;
    transform: rotateY(180deg);
    font-family: Avant Garde Gothic,sans-serif;
    font-size: 20px;
  }
  
  .userPicture {
    overflow: hidden;
    object-fit: cover;
    width: 225px;
    height:225px;
    border: 4px solid #55ddff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .userPicture svg {
    width: 2.5rem;
    fill: currentColor;
  }
  
  
  .socialMedia:before {
    content: " ";
    display: block;
    width: 100%;
    height: 2px;
    margin: 20px 0;
    background: #fffd82;
  }
  
  .socialMedia img {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: space-between;
  }
  

  .flipCardInner{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flipCardInner:hover  .flipCardInner{
    transform: rotateY(180deg)
  }

  .flipCardInnerFront .flipCardInnerBack{
    box-shadow:  0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid red;
    border-radius: 1rem;
  }

  @media (max-width: 1090px) {

    .title {
      font-size: 10vw;
      font-weight: 900;
      text-align: center;
      margin: 0;
      font-family: "Barbie-Dolly";
      color: #E10686;
      transform: rotate(-9deg);
      -webkit-text-stroke: 0.5px white; 
      text-stroke: 0.5px white;
      text-shadow: 1px 1px 3px #404040;
    }
  } 

  @media (max-width: 540px) {

    .title {
      font-size: 15vw;
      font-weight: 900;
      text-align: center;
      margin: 0;
      font-family: "Barbie-Dolly";
      color: #E10686;
      transform: rotate(-9deg);
      -webkit-text-stroke: 0.5px white; 
      text-stroke: 0.5px white;
      text-shadow: 1px 1px 3px #404040;
    }
  } 

  


.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #E10686;
  border-top: 2px solid #ffd0e7;;
  width: 100vw;
  height: 7vh;
  border-radius: 2px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999; 
}


.infoFooter{
  color: white;
  font-family: Avant Garde Gothic,sans-serif;
  font-weight: 500;
  line-height: 1.2;

}


  
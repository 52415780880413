/* notFound.module.css */

.notFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../img/meme.jpg");
    background-size: cover; /* La imagen se ajustará para cubrir todo el contenedor */
    width: 100vw;
    height: 100vh;
  }
  
  .notFoundTitle {
    margin-top: 10vh; /* Ajusta el margen superior para separar el texto del borde superior */
    font-family: Avant Garde Gothic,sans-serif;
    color: #e10686;
    font-size: 9vw;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
  }
  
  .notFoundSubTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: Avant Garde Gothic,sans-serif;
    color: #e10686;
    font-size: 6vw;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
  }
  
@font-face {
  font-family: "Barbie";
  src: url("../src//fonts/barbie.ttf");
}

@font-face {
  font-family: "Barbie-Dolly";
  src: url("../src//fonts/DollieScript_PersonalUse.ttf");
}

@font-face {
  font-family: "Barbie-Antiguo";
  src: url("../src//fonts/absolute-blonde.ttf");
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Avant Garde Gothic,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/img/sky-and-clouds.jpeg");
  background-repeat: no-repeat;
  background-size:auto;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


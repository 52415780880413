.flipCard {
    background-color: transparent;
    width: 470px;
    height: 650px;
    perspective: 1000px;
    font-family: sans-serif;
    margin-bottom: 70px;
  }
  
  .title {
    font-size: 4rem;
    font-weight: 900;
    text-align: center;
    margin: 0;
    font-family: "Barbie-Dolly";
    color: #E10686;
    transform: rotate(-9deg);
    -webkit-text-stroke: 0.5px white; 
    text-stroke: 0.5px white;
    text-shadow: 1px 1px 3px #404040;
  }
  

  .flipCardFront {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid #e10686;
    border-radius: 1rem;
    background: linear-gradient(120deg,#e10686 60%, #e10686  88%,
    #fffd82 40%,#fffd82 48%);
    color: white;
    font-family: Avant Garde Gothic,sans-serif;
  }
  
  
  .socialMedia:before {
    content: " ";
    display: block;
    width: 100%;
    height: 2px;
    margin: 20px 0;
    background: #fffd82;
  }
  
  .socialMedia img {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: space-between;
  }
  
 
  

.cardContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90vw;
    margin: 0 auto;
    margin-bottom: 10vh;
    padding: 5px
  }

.portfolioTitle{
    font-family: "Barbie-Antiguo";
    color: #E10686; 
    font-size: 400%;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
}


.infoContainer{
    background: #ffd0e7;
    border: 2px solid #f300a3 ;
    border-radius: 5px;
    color:#f300a3; 
    width: 60%;
    margin: 0 auto;
    padding: 2vh;
}

.textContainer{
    font-family: Avant Garde Gothic,sans-serif;
    font-weight: 500;
    line-height: 1.2;
}


.filterContainers{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterControls{
    background: #f300a3;
    border-radius: 5px;
    color:white;
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;

}

.menu {
 
    width: 15%;
    justify-content: right;
    align-content: center; 
    align-items: center; 
    font-size: 25px; 
    
  }

  .filterButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 2px;
    font-family: Avant Garde Gothic,sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }
  

  
.checkboxGroup{
    background: #ffd0e7;
    color:#f300a3; 
}

@media (max-width: 540px) {
  .filterContainers{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
  
  }

}
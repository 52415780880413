.contactTitle{
    font-family: "Barbie-Antiguo";
    color: #E10686; 
    font-size: 400%;
    -webkit-text-stroke: 2px white;
    text-stroke: 2px white;
    text-shadow: 1px 1px 3px #404040;
}

.contacto{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.contacto img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.contacto img:hover{
    background:   #55ddff;
    border-radius: 50%;
  }
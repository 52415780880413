.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 90vw;
    margin: 0 auto;    
  }
  
.containerImagenNoe{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0px auto;
    margin-top: 10px;
    order: 0
}

.containerImagenNoe img{
    max-width: 70vh;
    max-height:70vh;
    border: 10px solid #E10686;
    margin: 0 auto;
}

.infoHome{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
    order: 1
}

.title{
    font-family: "Barbie-Dolly";
    font-size: 10vw;
    color: white;
    transform: rotate(-9deg);
    -webkit-text-stroke: 2px #E10686;
    text-stroke: 2px #E10686;
    text-shadow: 1px 1px 3px #404040;
    line-height: 0;
}

.textHome{
    font-family: Avant Garde Gothic,sans-serif;
    font-size: 1.5vw;
    color: #E10686;
    margin: 0;
    line-height: 0;
}


@media (max-width: 1090px) {

    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        width: 90vw;
        height: 90vh;
        margin: 5px auto;
        min-height: calc(100vh - 14vh);
    }

    .containerImagenNoe{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 0px auto;
        order: 1;
    }

    .containerImagenNoe img{
        max-width: 60vh;
        max-height: 50vh;
        border: 10px solid #E10686;
    }
   
    .title{
        font-family: "Barbie-Dolly";
        font-size: 12vw;
        width: max-content;
        color: white;
        transform: rotate(-9deg);
        -webkit-text-stroke: 2px #E10686;
        text-stroke: 2px #E10686;
        text-shadow: 1px 1px 3px #404040;
        line-height: 0;
    }

    .infoHome{
        order: 0
    }

    .textHome{
        font-family: Avant Garde Gothic,sans-serif;
        font-size: 2.5vw;
        color: #E10686;
        margin: 0;
        line-height: 0;
    }
} 

@media (max-width: 540px) {

    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        width: 90vw;
        height: 90vh;
        height: min-content;
        margin: 0px auto;
    }

    .containerImagenNoe{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 0px auto;
        /* margin-top: 10px;
        margin-bottom: 0px; */
        order: 1;
    }

    .containerImagenNoe img{
        max-width: 60vh;
        max-height: 55vh;
        border: 10px solid #E10686;
  
    }
   
    .title{
        font-family: "Barbie-Dolly";
        font-size: 17vw;
        width: max-content;
        color: white;
        transform: rotate(-9deg);
        -webkit-text-stroke: 2px #E10686;
        text-stroke: 2px #E10686;
        text-shadow: 1px 1px 3px #404040;
        line-height: 0;
    }

    .infoHome{
        order: 0
    }

    .textHome{
        font-family: Avant Garde Gothic,sans-serif;
        font-size: 3.5vw;
        color: #E10686;
        margin: 0;
        line-height: 0;
    }
} 


.App {
  text-align: center;
  width: 100vw; 
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.AppContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    min-height: calc(110vh - 14vh - 10vh)
}


